import React from 'react';
import { graphql } from 'gatsby';
import {
  DontDo,
  IconItemList,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="User"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Icons" tierThree="User" />
      <V5Notice
        componentName="Icon"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-icon--docs"
      />
      <PageNavigation links={['Icons', 'Usage', 'Platform']} />
      <Section title="Icons">
        <IconItemList data={data} />
      </Section>
      <Section title="Usage">
        <Paragraph>
          User icons are meant to identify our users collectively, but are not
          meant to strip away any personality or identification. Use these when
          adding functions to an interface that affects these groups.
        </Paragraph>
        <DontDo
          dontText="remove a user's identity or personality."
          doText="represent users in an action or as a group."
          imgFilename="icons-users"
        />
      </Section>
      <Section title="Platform">
        <PlatformTable
          platforms={{
            web: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            apple: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            android: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query iconUserDesign($title: String = "User") {
    ...iconDataDesign
  }
`;
